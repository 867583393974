import React from "react";
import { SEO, MarkdownContent, Image } from "@bluefin/components";
import { Grid, Segment } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import {
  getComponentFiles,
  getComponentContentNodeContent,
} from "../utils/utils";

export default class SpaSalemPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          title={fishermanBusinessWebsitePage.seoTitle}
          description={fishermanBusinessWebsitePage.description}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <Grid
          className={
            "custom-page-spa-windham custom-page-grid custom-page-grid"
          }
          style={{}}
          stackable={true}
        >
          <Grid.Row
            className={"vpgkxlxkxifzxfoz"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"cxljawxbkfmsdnti"}
              style={{ padding: 16 }}
              width={8}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "e1ca440e-78fa-44d2-b97e-8c4f2bf59241",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"qlxalvmicifledoj"}
            style={{ padding: 16 }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"nttarowhwptowqri"}
              style={{ padding: 16, background: "#e4d7d7" }}
              width={12}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "cabda1b8-01df-4026-bb74-6d4efb8e8d08",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"qbsemnaqkhumxpqf"}
            style={{ padding: 16 }}
            verticalAlign={"middle"}
            columns={2}
          >
            <Grid.Column className={"crmiryjmmyootowg"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", backgroundSize: "cover" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "3dd8e92a-798e-486c-a8ec-f8df858fc1c0",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"siipultzphpjmitc"}
              style={{ padding: 16 }}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "b262c892-429f-4aed-b43e-ada3ab9a519a",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"wnhhegmofhfjhzep"}
            style={{ padding: 16, background: "#e4d6d7" }}
            verticalAlign={"middle"}
            reversed={"mobile"}
            columns={2}
          >
            <Grid.Column
              className={"aoehtaihpttbqdfz"}
              style={{ padding: 16 }}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "da110852-6176-4527-ae59-f45717686395",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column className={"nwxpgyrnemqepxwu"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{}}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "21c474e3-0bd9-494c-bf3c-715aa6f20cae",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"qsnckxvauwmjqafs"}
            style={{ padding: 16 }}
            verticalAlign={"middle"}
            columns={2}
          >
            <Grid.Column className={"rljsbegdjrqehzea"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", backgroundSize: "cover" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "6d21f469-b27d-43fb-9f6f-eb298f9d9e95",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"hspwsdinjoluagyo"}
              style={{ padding: 16 }}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "7409434f-d02d-4bfb-a8df-310a4bb4b726",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"vrkcwuxejtoypkiw"}
            style={{ padding: 16, background: "#e4d6d7" }}
            verticalAlign={"middle"}
            columns={2}
          >
            <Grid.Column
              className={"ctgaezchvyyxysrr"}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "c214f914-7751-4bce-ad6a-87c8369d0b13",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"uhnufeqakofdptea"}
              style={{ padding: 16 }}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "aa1e0a63-83db-4af2-b98d-298c288df2f5",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"bfbupwjkosblremt"}
            style={{ padding: 16 }}
            verticalAlign={"middle"}
            columns={2}
          >
            <Grid.Column className={"welpfzasjcunwywk"} style={{ padding: 16 }}>
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <Image
                  className={"image-block"}
                  style={{ minHeight: "240px", backgroundSize: "cover" }}
                  src={getComponentFiles({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "Image",
                    componentIdentifier: "e20d2421-0974-4bd6-b810-f1376d839071",
                    numToSelect: 1,
                  })}
                  background={false}
                />
              </Segment>
            </Grid.Column>
            <Grid.Column
              className={"eotgtsmmitqqzogg"}
              style={{ padding: 16 }}
              textAlign={"left"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "f7c3b598-035b-4415-ad21-3e687cbbfa80",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row
            className={"psmlywamgpcljmfx"}
            style={{ padding: 16, background: "#e4d6d7" }}
            textAlign={"center"}
            verticalAlign={"middle"}
          >
            <Grid.Column
              className={"tcychkbqfdcqolxe"}
              style={{ padding: 16 }}
              width={8}
              textAlign={"center"}
              verticalAlign={"middle"}
            >
              <Segment
                className={""}
                style={{
                  border: "none",
                  boxShadow: "none",
                  background: "none",
                }}
              >
                <MarkdownContent
                  className={"text-block"}
                  style={{}}
                  content={getComponentContentNodeContent({
                    components: fishermanBusinessWebsitePage.components,
                    componentName: "MarkdownContent",
                    componentId: "aa22b899-11e7-49d0-ab67-eaf6fed061d7",
                    defaultValue: "",
                  })}
                />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(_id: { eq: 26761 }) {
      title
      seoTitle
      description
      components {
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
        order
        fastId
        componentIdentifier
        contentNodes {
          content
        }
      }
    }
  }
`;
